import { graphql } from "gatsby"
import IDataPageProps from "../interfaces/data-page-props"
import PostsLayout from "../layouts/posts-layout"
import * as React from "react"
import Seo from "../layouts/seo"

export default function Page({ data, location }: IDataPageProps) {
  return <PostsLayout title="News" data={data} location={location} />
}

export const Head = () => <Seo title="News" />

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/news/" } }
    ) {
      nodes {
        excerpt(format: HTML)
        fields {
          date
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
